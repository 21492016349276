import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";
import * as whintegration from "@mod-system/js/wh/integration";
import "./mindsetkracht.scss";

import "@mod-nubiumreviews/webcomponents";

dompack.onDomReady(() => {
  new mso.MSOSite();

  if (whintegration.config.site.reviewsfooter) {
    let footer = dompack.qS(".footer > .ww-grid");

    let element = document.createElement("div");
    element.classList.add("footerreviews");
    element.innerText = whintegration.config.site.reviewsfooter;
    footer.appendChild(element);
  }
});
